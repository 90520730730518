import React, { Component } from 'react'
import './Landmark.css'
import PropTypes from 'prop-types'

class Landmark extends Component {

	constructor(props){
		super(props)
		this.marginTop = this.props.f / 4
		this.marginRight = this.props.f / 12
		this.transform = "matrix(1,0,0,-1,0,0)"
		this.fontSize = this.props.f / 4
		this.adjustment = this.props.f / 12
		this.y = []
		this.x = []
		for(let i = 0; i < this.props.row; i++){
			this.y.push(i)
		}
		for(let i = 0; i < this.props.col; i++){
			this.x.push(i)
		}
	}

	static propTypes = {
		col: PropTypes.number.isRequired,
		row: PropTypes.number.isRequired
	}

	render(){
		return (
			<g>
				{this.y.map((y) => (
					<text 
						key = { y.toString()+"_left" }
						x={ 0 } 
						y={ (-1 * y * this.props.f)  + this.adjustment } 
						dx={ 0 - this.props.offset - this.marginRight }
						dy={ 0 }
						fontSize={ this.fontSize } 
						transform={ this.transform }
						textAnchor="end"
					> 
					{ y }
					</text>
				))}
				{this.x.map((x) => (
					<text 
						key = { x.toString()+"_bottom" }
						x={ x * this.props.f } 
						y={ 0 } 
						dx={ -1 * this.adjustment }
						dy={ this.props.offset + this.marginTop }
						fontSize={ this.fontSize } 
						transform={ this.transform }
						textAnchor="start"
					> 
					{ x }
					</text>
				))}
			</g>
		)
	}
}

export default Landmark
