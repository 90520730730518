import React, { Component } from 'react'
import './Illustrated.css'

class Illustrated extends Component {

	constructor(props){
		super(props)
		this.handleIllustrateButtonClick = this.handleIllustrateButtonClick.bind(this)
	}

	handleIllustrateButtonClick(){
		this.props.onClick(this.props.stones, this.props.moves)
	}

  render(){
		return (
			<React.Fragment>
				{ this.props.children }
				<button 
					type="button" 
					className="btn illustrate-btn"
					onClick={ this.handleIllustrateButtonClick }
				>
						<svg 
							className="play-icon" 
							height="25" width="25"
							viewBox="-10,-10,120,120"
						>
							<path d="M 10 5 L 90 45 Q 100 50 90 55 L 10 95 Q 0 100 0 90 L 0 10 Q 0 0 10 5 Z"/>
						</svg>
				</button>
			</React.Fragment>
  	)
	}
}

export default Illustrated
