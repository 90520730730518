
export class MoveIsNotRecognized {
	constructor(move){
		this.move = move
	}
}

export class OutOfGameBoard {
	constructor(position){
		this.position = position
	}
}

export class StoneAlreadyExists {
	constructor(position){
		this.position = position
	}
}

export class StoneDoesNotExist {
	constructor(position){
		this.position = position
	}
}
