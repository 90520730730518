import React, { Component } from 'react'
import './MoveEditor.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons' 

class MoveEditor extends Component {

	constructor(props){
		super(props)
		this.state = { value: '' }
		this.onChange = this.onChange.bind(this)
		this.handleAddButtonClick = this.handleAddButtonClick.bind(this)
		this.cancel = this.cancel.bind(this)
		this.onKeyPress = this.onKeyPress.bind(this)
	}

	onChange(event){
		this.props.onChange(event.target.value)
	}

	handleAddButtonClick(event){
		this.props.handleAddButtonClick(this.props.move) 
	}

	cancel(){
		this.props.handleCancel()
	}

	onKeyPress(e){
		if(e.key === "Enter"){
			this.props.handleEnter(this.props.move)
		}
	}

  render(){
		 return ( 
			 	 <div className="input-group">
					 <input 
			 				type="text" 
			 				className={ "form-control "+( this.props.error.length > 0 ? "my-text-danger" : "my-text-success" ) } 
			 				placeholder="enter move"
			 				value={ this.props.move }
			 				onChange={ this.onChange }
			 				disabled={ this.props.disabled }
			 				onKeyPress={ this.onKeyPress }
			 		 />
			 		 <div className="input-group-append">
			 			<button 
			 				className="btn my-btn-primary" 
			 				disabled={ this.props.move.length === 0 || this.props.error.length > 0 || this.props.disabled }
			 				onClick={ this.handleAddButtonClick }
			 			>
			 				<FontAwesomeIcon icon={ faPlus } /> Add
			 			</button>
			 			<button 
			 				className="btn my-btn-danger" 
			 				disabled={ this.props.disabled }
			 				onClick={ this.cancel }
			 			>
			 				<FontAwesomeIcon icon={ faTimes } /> Cancel
			 			</button>
			 		 </div>
			 	 </div>
		 )
  }
}

export default MoveEditor
