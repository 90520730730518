import React, { Component } from 'react'
import './Board.css'
import Stone from '../stone/Stone.js'
import Line from '../line/Line.js'
import StoneSelector from '../stoneSelector/StoneSelector.js'
import AnimatedStone from '../animatedStone/AnimatedStone.js'
import SelectedStone from '../selectedStone/SelectedStone.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPaper } from '@fortawesome/free-regular-svg-icons' 
import { faTimes } from '@fortawesome/free-solid-svg-icons' 
import cloneDeep from 'lodash/cloneDeep'
import color from '../fanorona/color.js'
import Landmark from '../landmark/Landmark.js'

class Board extends Component {

	static defaultProps = {
		hideNewButtons: false,
		hideDeleteButton: false,
		hideBreakButton: false,
		showLandMark: true
	}

	constructor(props){
		super(props)
		this.state = {
			selectedStone: null,
			colorOfNewStone: color.white
		}
		this.onAnimationComplete = this.onAnimationComplete.bind(this)
		this.handleClickOnStone = this.handleClickOnStone.bind(this)
		this.handleClick = this.handleClick.bind(this)
		this.handleClickOnDeleteStoneButton = this.handleClickOnDeleteStoneButton.bind(this)
		this.handleClickOnBreakButton = this.handleClickOnBreakButton.bind(this)
		this.handleClickOnStoneSelector = this.handleClickOnStoneSelector.bind(this)
		this.stonesRadius = this.props.f / 5
	}

	handleClickOnStoneSelector(color){
		this.setState({ colorOfNewStone: color })
	}

	handleClickOnDeleteStoneButton(){
		const stone = cloneDeep(this.state.selectedStone)
		this.props.handleDeletion(stone)
		this.setState({ selectedStone: null })
	}

	handleClickOnBreakButton(){
		const stone = cloneDeep(this.state.selectedStone)
		this.props.handleBreak(stone)
		this.setState({ selectedStone: null })
	}

	getFanoronaCoordinate(coordinate){
		let c = coordinate / this.props.f
		const prec = 0.3
		const cTrunc = Math.trunc(c)
		const d = Math.abs(c - cTrunc)

		if(d < prec){
			c = cTrunc
		} else if(d >= prec && d <= (1-prec)){
			c = null
		} else {
			c = cTrunc + 1
		}

		return c
	}

	getPosition(point){
		const x = this.getFanoronaCoordinate(point.x)
		const y = this.getFanoronaCoordinate(point.y)
		if(x === null || y === null){
			return null
		}
		return { x, y }
	}

	handleClick(e){
		if(this.props.readOnly === true){
			return
		}

		const svg = e.currentTarget
		const point = svg.createSVGPoint()
		point.x = e.clientX
		point.y = e.clientY

		const newPoint = point.matrixTransform(svg.getScreenCTM().flipY().inverse())

		const position = this.getPosition(newPoint) 

		if(position){
			if(this.state.selectedStone){
				this.props.handleMove({
					stone: this.state.selectedStone, 
					destination: position
				})
				this.setState({ selectedStone: null })
			} else {
				this.props.handleCreation({
					x: position.x,
					y: position.y,
					color: this.state.colorOfNewStone
				})
			}
		}

		e.stopPropagation()
	}

	handleClickOnStone(stone){
		this.setState({ selectedStone: stone })	
	}

	onAnimationComplete(){
		this.props.onAnimationComplete()
	}

	viewBox(){
		const margin = ( this.props.f / 8 ) + this.stonesRadius + ( this.props.f / 4 )
		const width = this.maxColNumberF() + margin * 2
		const height = this.maxRowNumberF() + margin * 2
		const minX = -1 * margin 
		const minY = -1 * margin - this.maxRowNumberF()
		const separator = ","
		return "" 
			+minX.toString()
			+separator
			+minY.toString()
			+separator
			+width.toString()
			+separator
			+height.toString()
	}

	maxColNumberF(){
		return (this.props.col - 1) * this.props.f
	}

	maxRowNumberF(){
		return  (this.props.row - 1) * this.props.f
	}

	static keyOfStone({x, y}){
		return x.toString() + '_' + y.toString()
	}

	static keyOfLine({x1, y1, x2, y2}){
		return x1.toString() 
			+ '_'
			+ y1.toString() 
			+ '_'
			+ x2.toString()
			+ '_'
			+ y2.toString()
	}

  render(){
		 return ( 
				<div>
					<svg 
			 			className="board" 
			 			x={0} y={0} 
			 			height="100%" width="100%" 
			 			viewBox={ this.viewBox() }
			 			onClick={ this.handleClick }
			 		>
			 			<g transform="matrix(1,0,0,-1,0,0)">
							{this.props.lines.map((line) => (
								<Line 
									key = { Board.keyOfLine(line) }
									x1={ line.x1 } 
									y1={ line.y1 }
									x2={ line.x2 }
									y2={ line.y2 }
									f={ this.props.f }
								/>
							))}

			 				{ this.props.showLandMark === true && 
								<Landmark 
									col={ this.props.col } 
									row={ this.props.row } 
									offset= { this.stonesRadius }
									f={ this.props.f }
								/>
							}

			 				{this.props.stones.map((stone) => {

								if(this.props.animation){
									if(stone.x === this.props.animation.stone.x &&
									 stone.y === this.props.animation.stone.y
									){
										return null
									}
								}

								if(this.state.selectedStone){
									if(stone.x === this.state.selectedStone.x &&
										stone.y === this.state.selectedStone.y
									){
										return (
											<SelectedStone key={ Board.keyOfStone(stone) }>
												<Stone 
													key={ Board.keyOfStone(stone) }
													x={ stone.x } 
													y={ stone.y } 
													f={ this.props.f } 
													r={ this.stonesRadius } 
													color={ stone.color }
												/>
											</SelectedStone>
										)
									}
								}

								return (
									<Stone 
										key={ Board.keyOfStone(stone) }
										x={ stone.x } 
										y={ stone.y } 
										f={ this.props.f } 
										r={ this.stonesRadius } 
										color={ stone.color }
										handleClick={ this.props.readOnly ? null : this.handleClickOnStone }
									/>
								)
						  })}

							 {this.props.animation && 
									<AnimatedStone 
										animationType={ this.props.animation.type }
										onAnimationComplete={ this.onAnimationComplete }
										to = { this.props.animation.to }
									>
										<Stone
											x={ this.props.animation.stone.x }
											y={ this.props.animation.stone.y }
											f={ this.props.f }
											r={ this.stonesRadius }
											color={ this.props.animation.stone.color }
										/>
									</AnimatedStone>
							 }
			 			</g>
					</svg>
					{ 
					(
						this.props.hideDeleteButton === false || 
						this.props.hideBreakButton === false ||
						this.props.hideNewsButton === false
					) && 
			 		<div className="d-flex flex-row flex-wrap pt-1 justify-content-center">
			 			{ this.props.hideNewButtons === false && 
							<StoneSelector 
								onClick={ this.handleClickOnStoneSelector }  
								selected={ this.state.colorOfNewStone }
								disabled={ this.props.readOnly }
							/>
						}
			 			{ (this.props.hideDeleteButton === false || this.props.hideBreakButton === false) && 
							<div className="btn-group pt-1 pt-md-0">
								{ this.props.hideDeleteButton === false && 
									<button 
										type="button" 
										className="btn btn-outline-secondary"
										onClick={ this.handleClickOnDeleteStoneButton }
										disabled={ this.state.selectedStone === null || this.props.readOnly }
									> 
										<FontAwesomeIcon icon={ faTimes } /> Delete stone
									</button>
								}
								{ this.props.hideBreakButton === false && 
									<button 
										type="button" 
										className="btn btn-outline-secondary"
										onClick={ this.handleClickOnBreakButton }
										disabled={ this.state.selectedStone === null || this.props.readOnly }
									> 
										<FontAwesomeIcon icon={ faHandPaper } /> Break
									</button>
								}
							</div>
						}
			 		</div>
					}
				</div>
		 )
  }
}

export default Board
