import React, { Component } from 'react'
import './StatusBar.css'

class StatusBar extends Component {
  render(){
		let className = "status-bar"
		if(this.props.error === true){
			className = className + " status-bar-error"
		}
	 return ( 
		 <div className={ className }>
		 { this.props.message ? this.props.message : "nothing to report" }
		 </div>
	 )
  }
}

export default StatusBar
