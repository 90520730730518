import React, { Component } from 'react'
import './Move.css'

class Move extends Component {

	constructor(props){
		super(props)
		this.handleClick = this.handleClick.bind(this)
	}

	handleClick(event){
		this.props.onClick(this.props.index)
	}
	
  render(){
		 return ( 
			 <button 
			 		type="button" 
			 		className="btn btn-outline-secondary m-1" 
			 		onClick={ this.handleClick }
			 		disabled={ this.props.disabled }
			 >
				 { this.props.move }
			 </button>
		 )
  }

}

export default Move
