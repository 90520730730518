import React, { Component } from 'react'
import './AnimatedStone.css'
import Stone from '../stone/Stone.js'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import animationType from '../fanorona/animationType.js'

const transition = {
	duration: 1,
	ease: "easeIn"
}

class AnimatedStone extends Component {

	constructor(props){
		super(props)

		if(this.props.animationType === animationType.move && !this.props.to){
			throw new Error("props 'to' is mandatory if animationType is move")
		}

		this.onAnimationComplete = this.onAnimationComplete.bind(this)
		
		const stone = React.cloneElement(
			this.props.children
		) 

		this.state = {
			stone,
			animationComplete: false
		}
	}

	onAnimationComplete(){
		if(this.props.animationType === animationType.deletion){
			this.setState({ stone: null, animationComplete: true })
		} else if(this.props.animationType === animationType.move){
			this.setState({ 
				stone: React.cloneElement(
					this.props.children, 
					{ x: this.props.to.x, y: this.props.to.y }
				),
				animationComplete: true
			})
		} else {
			this.setState({ stone: this.props.children, animationComplete: true })
		}
		this.props.onAnimationComplete()
	}

	static propTypes = {
		children: function(props, propName, componentName){
			if(!(props[propName].type === Stone)){
				return new Error(
					'child of '+componentName+' must be only one element of type Stone.'
				)
			}
		},
		to: PropTypes.shape({ x: PropTypes.number.isRequired, y: PropTypes.number.isRequired }),
		animationType: PropTypes.oneOf(Object.keys(animationType))
	}

	animateMove(){
		const deltaX = ( this.props.to.x - this.props.children.props.x ) * this.props.children.props.f 
		const deltaY = ( this.props.to.y - this.props.children.props.y ) * this.props.children.props.f
		return { x: deltaX, y: deltaY }
	}

	render(){

		let animate = null
		let initial = null

		switch(this.props.animationType){
			case animationType.move:
				animate = this.animateMove()
				break
			case animationType.deletion:
				initial = { opacity: 1 }
				animate = { opacity: 0 }
				break
			case animationType.creation:
				initial = { opacity: 0 }
				animate = { opacity: 1 }
				break
			case animationType.break:
				animate = { scale: [1, 2, 1] }
				break
			default:
		}

		if(this.state.animationComplete){
			return this.state.stone
		}

		return (
			<motion.g
				initial = { initial }
				animate = { animate }
				transition = { transition }
				onAnimationComplete = { this.onAnimationComplete }
			>
			{ this.state.stone }
			</motion.g>
		)
	}
}

export default AnimatedStone
