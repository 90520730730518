import React from 'react'
import './Cursor.css'
import { motion } from 'framer-motion'

function ease(progress){
	if(progress >= 0 && progress < 0.5){
		return 0
	}
	return 1
}

function Cursor(props){
	return (
		<motion.div 
			className="cursor d-inline-block"
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{
				ease,				
				loop: 10,
				duration: 1.5
			}}
		>
		</motion.div>
	)
}

export default Cursor
