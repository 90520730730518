import React, { Component } from 'react'
import './NavBar.css'
import { Link, withRouter } from "react-router-dom"
import route from "../route.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
	faPen, 
	faGraduationCap,
	faHome,
	faQuestion,
	faAt
} from '@fortawesome/free-solid-svg-icons' 
import $ from 'jquery'

class NavBar extends Component {

	constructor(props){
		super(props)
		this.linkClassName = this.linkClassName.bind(this)
	}

	linkClassName(route){
		return "nav-item" + ( this.props.location.pathname === route ? " active" : "")
	}

	componentDidMount(){
		$(".navbar-nav > li > a").on("click", function(){
			$(".navbar-collapse").collapse("hide")
		})
	}

  render(){
		return (
				<nav className="navbar navbar-expand-sm navbar-light fixed-top my-navbar shadow">
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="collapsibleNavbar">
							<ul className="navbar-nav">
								<li className={ this.linkClassName(route.home) }>
									<Link className="nav-link" to={ route.home }>
										Home&nbsp;
			 							<FontAwesomeIcon icon={ faHome } />
									</Link>
								</li>
								<li className={ this.linkClassName(route.boardEditor) }>
									<Link className="nav-link" to={ route.boardEditor }>
										Board editor&nbsp;
			 							<FontAwesomeIcon icon={ faPen } />
									</Link>
								</li>
								<li className={ this.linkClassName(route.learn) }>
									<Link className="nav-link" to={ route.learn }>
										Learn&nbsp;
			 							<FontAwesomeIcon icon={ faGraduationCap } />
									</Link>
								</li>
								<li className={ this.linkClassName(route.help) }>
									<Link className="nav-link" to={ route.help }>
										Help&nbsp;
			 							<FontAwesomeIcon icon={ faQuestion } />
									</Link>
								</li>
								<li className={ this.linkClassName(route.theAuthor) }>
									<Link className="nav-link" to={ route.theAuthor }>
										The author&nbsp;
			 							<FontAwesomeIcon icon={ faAt } />
									</Link>
								</li>
							</ul>
					</div>
				</nav>
  	)
	}
}

export default withRouter(NavBar)
