import React, { Component } from 'react'
import './BoardEditor.css'
import Board from '../board/Board.js'
import MoveEditor from '../moveEditor/MoveEditor.js'
import ListOfMoves from '../listOfMoves/ListOfMoves.js'
import FanBoard from '../fanorona/Board.js'
import way from '../fanorona/way.js'
import cloneDeep from 'lodash/cloneDeep'
import StatusBar from '../statusBar/StatusBar.js'
import { 
	positionToString, 
	stoneAndHisDestinationToString, 
	deletionToString,
	breakToString,
	creationToString
} from '../fanorona/notation.js'
import {
	OutOfGameBoard,
	MoveIsNotRecognized,
	StoneAlreadyExists,
	StoneDoesNotExist
} from '../fanorona/exception.js'
import { explodeMoves } from '../fanorona/notation.js'

const defaultCol = 9
const defaultRow = 5
const defaultWayToFill = way.right
const defaultBoard = new FanBoard(defaultCol, defaultRow, defaultWayToFill)

class BoardEditor extends Component {

	static defaultProps = {
		readOnly: false,
		board: defaultBoard,
		stones: false,
		moves: false
	}

	constructor(props){
		super(props)
		this.state = this.getStateFromProps()
		this.onMoveEditorChange = this.onMoveEditorChange.bind(this)
		this.handleAddButtonClick = this.handleAddButtonClick.bind(this)
		this.handlePreviousMove = this.handlePreviousMove.bind(this)
		this.handleNextMove = this.handleNextMove.bind(this)
		this.handleFirstMove = this.handleFirstMove.bind(this)
		this.handleLastMove = this.handleLastMove.bind(this)
		this.handleClickOnMove = this.handleClickOnMove.bind(this)
		this.handlePlay = this.handlePlay.bind(this)
		this.onAnimationComplete = this.onAnimationComplete.bind(this)
		this.handleStop = this.handleStop.bind(this)
		this.handleMoveFromBoard = this.handleMoveFromBoard.bind(this)
		this.handleError = this.handleError.bind(this)
		this.handleStoneDeletionFromBoard = this.handleStoneDeletionFromBoard.bind(this)
		this.handleBreak = this.handleBreak.bind(this)
		this.handleStoneCreationFromBoard = this.handleStoneCreationFromBoard.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
		this.cancelMove = this.cancelMove.bind(this)
		this.handleEnterMoveEditor = this.handleEnterMoveEditor.bind(this)
	}

	handleEnterMoveEditor(move){
		if(this.state.error || move === ''){
		} else {
			this.newMove(move)
		}
	}

	cancelMove(){
		if(this.state.move === ''){
			return
		}
		const board = this.actualBoard()
		this.setState({
			move: '',
			lastMove: '',
			error: '',
			board
		})
	}

	handleDelete(){
		const listOfMoves = this.state.listOfMoves
		listOfMoves.splice(this.state.cursor + 1)
		this.setState({
			listOfMoves
		})
	}

	getStateFromProps(){
		const animation = null

		const board = cloneDeep(this.props.board)

		if(this.props.stones !== false){
			board.stones = cloneDeep(this.props.stones)
		}
		
		const initialBoard = cloneDeep(board)

		let listOfMoves = []
		let cursor = 0
		if(this.props.moves !== false){
			listOfMoves = explodeMoves(this.props.moves)
			if(listOfMoves.length > 0){
				cursor = -1
			}
		}

		const state = {
			initialBoard,
			board,
			move: '',
			lastMove: '',
			error: '',
			listOfMoves,
			cursor,
			animation,
			playing: false,
			stop: false
		}

		return state
	}

	componentDidUpdate(prevProps){
		if(prevProps !== this.props){
			this.setState(this.getStateFromProps())
		}
	}

	handleStoneCreationFromBoard(stone){
		const n = creationToString(stone)
		this.execMoveFromBoard(n)
	}

	handleStoneDeletionFromBoard(stone){
		const d = deletionToString(stone)
		this.execMoveFromBoard(d)
	}

	handleBreak(stone){
		const b = breakToString(stone)
		this.execMoveFromBoard(b)
	}

	execMoveFromBoard(m){
		const board = cloneDeep(this.state.board) 
		try {
			board.execMove(m)
			this.setState({ board, error: '' })
			this.newMove(m)
		} catch(e) {
			this.handleError(e)
		}
	}

	handleMoveFromBoard(move){
		const m = stoneAndHisDestinationToString(move)
		this.execMoveFromBoard(m)
	}

	handleStop(){
		this.setState({ stop: true })
	}

	onAnimationComplete(){
		this.setState((state) => {
			if(state.stop === false){
				const cursor = state.cursor + 1
				const board = this.execMovesTill(cursor, state.listOfMoves, state.initialBoard)
				return { animation: null, cursor, board, playing: true }
			}
			return { animation: null, playing: false }
		}, () => {
			if(this.state.cursor < this.state.listOfMoves.length - 1 && this.state.stop === false){
				this.handlePlay()
			} else {
				this.setState({ playing: false, stop: false })
			}
		})
	}

	actualBoard(){
		const board = cloneDeep(this.state.initialBoard)
		this.state.listOfMoves.forEach((move) => {
			board.execMove(move)
		})
		return board
	}

	execMovesTill(cursor, listOfMoves, initialBoard){
		const board = cloneDeep(initialBoard)
		for(let i = 0; i <= cursor; i++){
			board.execMove(listOfMoves[i])
		}
		return board
	}

	handleClickOnMove(index){
		const board = this.execMovesTill(index, this.state.listOfMoves, this.state.initialBoard)
		this.setState({ cursor: index, board })
	}

	handlePreviousMove(){
		const cursor = this.state.cursor - 1
		const board = this.execMovesTill(cursor, this.state.listOfMoves, this.state.initialBoard)
		this.setState({ cursor, board })
	}

	handleNextMove(){
		const cursor = this.state.cursor + 1
		const board = this.execMovesTill(cursor, this.state.listOfMoves, this.state.initialBoard)
		this.setState({ cursor, board })
	}

	handleFirstMove(){
		const cursor = -1
		const board = this.execMovesTill(cursor, this.state.listOfMoves, this.state.initialBoard)
		this.setState({ cursor, board })
	}

	handleLastMove(){
		const cursor = this.state.listOfMoves.length - 1
		const board = this.execMovesTill(cursor, this.state.listOfMoves, this.state.initialBoard)
		this.setState({ cursor, board })
	}

	handlePlay(){
		const animation = this.state.board.moveToAnimation(
			this.state.listOfMoves[this.state.cursor + 1]
		)
		this.setState({ animation, playing: true })
	}

	handleAddButtonClick(move){
		this.newMove(move)
	}

	newMove(move){
		let listOfMoves = this.state.listOfMoves
		listOfMoves = listOfMoves.concat(FanBoard.dissect(move))
		this.setState(
			{ listOfMoves, move: '', cursor: ( listOfMoves.length - 1 ) }
		)
	}

	handleError(e){
		if(e instanceof OutOfGameBoard){
			this.setState({ 
				error: positionToString(e.position) + " is out of game board" 
			})
		} else if(e instanceof MoveIsNotRecognized){
			this.setState({ 
				error: e.move + " is not recognized" 
			})
		} else if(e instanceof StoneAlreadyExists){
			this.setState({ 
				error: "stone "+positionToString(e.position)+" already exists" 
			})
		} else if(e instanceof StoneDoesNotExist){
			this.setState({ 
				error: "stone "+positionToString(e.position)+" does not exist" 
			})
		}
	}

	onMoveEditorChange(value){
		let move = value.toLowerCase()

		try {
			const board = this.actualBoard() 


			if(this.state.lastMove.length < move.length){
				if(this.state.lastMove !== ''){
					board.execMoves(this.state.lastMove)
				}
				this.setState({ board, cursor: this.state.listOfMoves.length - 1 })
				const m = move.substring(this.state.lastMove.length, move.length)
				board.execMoves(m)
			} else {
				this.setState({ board, cursor: this.state.listOfMoves.length - 1 })
				board.execMoves(move)
			}
			
			this.setState({ board, error: '', lastMove: move })
		} catch(e) {
			this.handleError(e)
		}
	
		this.setState({ move })
	}

	cursorIsMax(){
		return ( this.state.listOfMoves.length - 1 === this.state.cursor )
	}

  render(){

		const board = <Board 
			col={ this.state.board.col } 
			row={ this.state.board.row } 
			f={ 200 } 
			stones={ this.state.board.stones }
			lines={ this.state.board.lines }
			animation={ this.state.animation }
			onAnimationComplete={ this.onAnimationComplete }
			handleMove={ this.handleMoveFromBoard }
			handleDeletion={ this.handleStoneDeletionFromBoard }
			handleCreation={ this.handleStoneCreationFromBoard }
			handleBreak={ this.handleBreak }
			readOnly={ this.state.playing || this.props.readOnly || (!this.cursorIsMax() && this.state.listOfMoves.length !== 0) }
			hideNewButtons={ this.props.readOnly }
			hideDeleteButton={ this.props.readOnly }
			hideBreakButton={ this.props.readOnly }
		/>

		const listOfMoves = <ListOfMoves 
			listOfMoves={ this.state.listOfMoves }
			handlePreviousMove={ this.handlePreviousMove }	
			handleNextMove={ this.handleNextMove }
			handleFirstMove={ this.handleFirstMove }
			handleLastMove={ this.handleLastMove }
			cursor={ this.state.cursor }
			handleClickOnMove = { this.handleClickOnMove }
			handlePlay = { this.handlePlay }
			playing = { this.state.playing }
			handleStop = { this.handleStop }
			navigationDisabled = { this.state.playing }
			hideList = { this.props.readOnly }
			readOnly = { this.props.readOnly }
			handleDelete = { this.handleDelete }
			deleteBtnDisabled = { this.state.playing }
		/>

	  if(this.props.readOnly === true){
			return (
				<div>
					<div className="row">
						<div className="col">
							{ board }
						</div>
					</div>
					<div className="row pt-1">
						<div className="col">
							{ listOfMoves }
						</div>
					</div>
				</div>
			)
		}

		return (
			<div>
					<div className="row">
						<div className="col">
							<StatusBar message={ this.state.error } error={ this.state.error !== "" }/>
						</div>
					</div>
					<div className="row pt-1">
						<div className="col-lg-6">
						{ board }
						</div>
						<div className="col-lg-6">
							<div className="row pt-1 pt-lg-0">
								<div className="col">
									<MoveEditor  
										move={ this.state.move } 
										onChange={ this.onMoveEditorChange } 
										error={ this.state.error }
										handleAddButtonClick={ this.handleAddButtonClick }
										disabled={ this.state.playing }
										handleCancel={ this.cancelMove }
										handleEnter={ this.handleEnterMoveEditor }
									/>
								</div>
							</div>
							<div className="row pt-1">
								<div className="col">
									{ listOfMoves }
								</div>
							</div>
						</div>
					</div>
			</div>
  	)
	}
}

export default BoardEditor
