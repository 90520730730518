import React, { Component } from 'react'
import './Help.css'
import Illustrated from '../illustrated/Illustrated.js'
import Illustration from '../illustration/Illustration.js'
import BoardEditor from '../boardEditor/BoardEditor.js'
import color from '../fanorona/color.js'

class Help extends Component {

	constructor(props){
		super(props)
		this.state = {
			showIllustration: false,
			stones: [],
			moves: ""
		}
		this.showIllustration = this.showIllustration.bind(this)
		this.closeIllustration = this.closeIllustration.bind(this)
	}

	showIllustration(stones, moves){
		this.setState({ showIllustration: true, stones: stones, moves: moves })
	}

	closeIllustration(){
		this.setState({ showIllustration: false })
	}

  render(){
		return (
			<div className="row">
				<div className="col">

					<Illustration show={ this.state.showIllustration } onClose={ this.closeIllustration }>
						<BoardEditor 
							stones={ this.state.stones }
							moves={ this.state.moves }
							readOnly={ true }
						/>
					</Illustration>

					<div>
						<h5 className="big-title">Notation</h5>
						<p>
							<span className="notation">?<strong className="important">x</strong>?</span> to design an intersection or a stone placed on this intersection.<br/>
							Replace the question marks with the coordinates of the intersection.<br/>
						</p>
						<p>
							<span className="notation"><strong className="important">m</strong>?x?<strong className="important">t</strong>?x?</span> to design a move from an <strong className="important">intersection to next intersection</strong>.<br/>
							<Illustrated
								stones={[
									{ x: 4, y: 2, color: color.white }
								]}
								moves="m4x2t5x3"
								onClick={ this.showIllustration }
							>
								Example: m4x2t5x3
							</Illustrated>
						</p>
						<p>
							<span className="notation"><strong className="important">b</strong>?x?</span> to design a <strong className="important">break</strong>.<br/>
							In other words, move from an intersection to <strong className="important"> the same intersection</strong>.<br/>
							<Illustrated
								stones={[
									{ x: 4, y: 2, color: color.white }
								]}
								moves="b4x2"
								onClick={ this.showIllustration }
							>
								Example: b4x2
							</Illustrated>
						</p>
						<p>
							<span className="notation"><strong className="important">d</strong>?x?</span> to design a deletion of a stone.<br/>
							<Illustrated
								stones={[
									{ x: 4, y: 2, color: color.white }
								]}
								moves="d4x2"
								onClick={ this.showIllustration }
							>
								Example: d4x2
							</Illustrated>
						</p>
						<p>
							<span className="notation"><strong className="important">nw</strong>?x?</span> to design a <strong className="important">creation of a white stone</strong>.<br/>
							<Illustrated
								stones={[]}
								moves="nw4x2"
								onClick={ this.showIllustration }
							>
								Example: nw4x2
							</Illustrated>
						</p>
						<p>
							<span className="notation"><strong className="important">nb</strong>?x?</span> to design a <strong className="important">creation of a black stone</strong>.<br/>
							<Illustrated
								stones={[]}
								moves="nb4x2"
								onClick={ this.showIllustration }
							>
								Example: nb4x2
							</Illustrated>
						</p>
					</div>
				</div>
			</div>
  	)
	}

}

export default Help
