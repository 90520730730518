import React, { Component } from 'react'
import './App.css'
import BoardEditor from './boardEditor/BoardEditor.js'
import Home from './home/Home.js'
import Learn from './learn/Learn.js'
import Help from './help/Help.js'
import Author from './author/Author.js'
import NavBar from './navBar/NavBar.js'
import route from "./route.js"
import {
	HashRouter,
	Switch,
	Route
} from "react-router-dom"

class App extends Component {

  render(){
		return (
			<HashRouter hashType="noslash">
				<div className="container-fluid">
					<NavBar />
					<div className="my-container">
						<Switch>
							<Route exact path={ route.home }>
								<Home />
							</Route>
							<Route path={ route.boardEditor }>
								<BoardEditor />
							</Route>
							<Route path={ route.learn }>
								<Learn />
							</Route>
							<Route path={ route.help }>
								<Help />
							</Route>
							<Route path={ route.theAuthor }>
								<Author />
							</Route>
						</Switch>
					</div>
				</div>
			</HashRouter>
  	)
	}
}

export default App
