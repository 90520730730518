import React, { Component } from 'react'
import './Line.css'

class Line extends Component {

	render(){
		return (
			<line 
				x1={ this.props.x1 * this.props.f }
				y1={ this.props.y1 * this.props.f } 
				x2={ this.props.x2  * this.props.f } 
				y2={ this.props.y2 * this.props.f }
			/>	
		)
	}
}

export default Line
