import React, { Component } from 'react'
import './Illustration.css'
import $ from 'jquery'
import BoardEditor from '../boardEditor/BoardEditor.js'
import shortid from 'shortid'


class Illustration extends Component {

	static propTypes = {
		children: function(props, propName, componentName){
			if(!(props[propName].type === BoardEditor)){
				return new Error(
					'child of '+componentName+' must be only one element of type BoardEditor.'
				)
			}
		}
	}

	constructor(props){
		super(props)
		this.id = "illustration"+shortid.generate()
		this.handleClickOnCloseButton = this.handleClickOnCloseButton.bind(this)
	}

	hashtaggedId(){
		return "#"+this.id
	}

	componentDidMount(){
		$(this.hashtaggedId()).modal({ backdrop: "static", show: this.props.show })
	}

	componentDidUpdate(){
		if(this.props.show){
			$(this.hashtaggedId()).modal("show")
		} else {
			$(this.hashtaggedId()).modal("hide")
		}
	}

	handleClickOnCloseButton(){
		this.props.onClose()
	}

  render(){
		return (
			<div className="modal" id={ this.id }>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<button 
								type="button" 
								className="close"
								onClick={ this.handleClickOnCloseButton }
							>
								&times;
							</button>
						</div>
						<div className="modal-body">
							{ this.props.children }
						</div>
					</div>
				</div>
			</div>
  	)
	}
}

export default Illustration
