import React, { Component } from 'react'
import './StoneSelector.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons' 
import color from '../fanorona/color.js'

class StoneSelector extends Component {

	constructor(props){
		super(props)
		this.onClick = this.onClick.bind(this)
	}

	onClick(color){
		if(typeof this.props.onClick === "function"){
			this.props.onClick(color)
		}
	}

  render(){
		 const activeClass = "active"
		 return ( 
			<div className="btn-group mr-1">
				<button 
					type="button" 
					className={ 
						"btn btn-outline-secondary"
						+ ( this.props.selected === color.white ? " " + activeClass : "" )
					}
			 		onClick={ ()=>{ this.onClick(color.white) } }
			 		disabled={ this.props.disabled }
				> 
					<FontAwesomeIcon icon={ faPlus }/> White stone
				</button>
				<button 
					type="button" 
					className={ 
						"btn btn-outline-secondary"
						+ ( this.props.selected === color.black ? " " + activeClass : "" )
					}
			 		onClick={ ()=>{ this.onClick(color.black) } }
			 		disabled={ this.props.disabled }
				> 
					<FontAwesomeIcon icon={ faPlus }/> Black stone
				</button>
			</div>
		 )
  }
}

export default StoneSelector
