import React, { Component } from 'react'
import './Learn.css'
import Illustration from "../illustration/Illustration.js"
import Illustrated from "../illustrated/Illustrated.js"
import color from "../fanorona/color.js"
import BoardEditor from '../boardEditor/BoardEditor.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
	faHandPaper 
} from '@fortawesome/free-solid-svg-icons' 

class Learn extends Component {

	constructor(props){
		super(props)
		this.state = {
			show: false,
			stones: [],
			moves: ""
		}
		this.handleClose = this.handleClose.bind(this)
		this.showIllustration = this.showIllustration.bind(this)
	}

	showIllustration(stones, moves){
		this.setState({ show: true, stones: stones, moves: moves })
	}

	handleClose(){
		this.setState({ show: false })
	}

  render(){
		return (
			<div className="row">
				<div className="col">

					<Illustration show={ this.state.show } onClose={ this.handleClose }>
						<BoardEditor 
							stones={ this.state.stones }
							moves={ this.state.moves }
							readOnly={ true }
						/>
					</Illustration>

					<div>
						<h5 className="big-title">Introduction</h5>
						<p>
							Fanorona is a very popular board game in Madagascar.<br/>
							The rules of Fanorona game are very simple.<br/>
							Unlike chess, all stones in Fanorona have same value.<br/>
							At the beginning of the game the number of white stones is equal to the number of black stones.
						</p>
					</div>


					<div>
						<h5 className="big-title">The basic rules</h5>
						<h6>
							<Illustrated stones={[]} moves="nw4x2" onClick={ this.showIllustration }>
								<span className="rule">Rule-1</span> A stone must be placed on empty <strong className="important">intersection</strong>.
							</Illustrated>
						</h6>
						<h6>
							<Illustrated 
								stones={[
									{ x: 4, y: 2, color: color.white }
								]}
								moves="m4x2t5x3"
								onClick={ this.showIllustration }
							>
							<span className="rule">Rule-2</span> The movement of a stone must be done along an existing line.
							</Illustrated>
						</h6>
							That line is called <strong className="important">movement line</strong>.<br/>
							<Illustrated  
								stones={[
									{ x: 4, y: 3, color: color.white }
								]}
								moves="m4x3t5x2"
								onClick={ this.showIllustration }
							>
								Counter-example:&nbsp;
							</Illustrated>
						<h6>
							<Illustrated
								stones={[
									{ x: 4, y: 1, color: color.white }
								]}
								moves="m4x1t4x2"
								onClick={ this.showIllustration }
							>
							<span className="rule">Rule-3</span> A stone should only be moved to a adjacent <strong className="important">intersection</strong>.
							</Illustrated>
						</h6>
							<Illustrated
								stones={[
									{ x: 4, y: 1, color: color.white }
								]}
								moves="m4x1t4x3"
								onClick={ this.showIllustration }
							>
								Counter-example:&nbsp;
							</Illustrated>
					</div>


					<div>
						<h5 className="big-title">The capturing rules</h5>
						<h6>
							<Illustrated
								stones={[
									{ x: 3, y: 2, color: color.white },
									{ x: 5, y: 2, color: color.black }
								]}
								moves="m3x2t4x2d5x2"
								onClick={ this.showIllustration }
							>
								<span className="rule">Rule-4</span> The capture of opponent's stone can be done only after the movement of a stone.
							</Illustrated>
						</h6>
						That movement is called <strong className="important">capturing move</strong>.<br/>
						<Illustrated
							stones={[
								{ x: 3, y: 2, color: color.white },
								{ x: 5, y: 2, color: color.black }
							]}
							moves="d5x2m3x2t4x2"
							onClick={ this.showIllustration }
						>
							Counter-example:&nbsp;
						</Illustrated>
						<h6><span className="rule">Rule-5</span> The <strong className="important">opponent's stone</strong> that can be captured is the stone adjacent to <strong className="important">arrival/starting intersection</strong> on the <strong className="important">movement line</strong>.</h6>
						So there are two types of capture:
						<ul>
							<li>
								<Illustrated
									stones={[
										{ x: 3, y: 2, color: color.white },
										{ x: 5, y: 2, color: color.black }
									]}
									moves="m3x2t4x2d5x2"
									onClick={ this.showIllustration }
								>
									capture by approach:&nbsp;
								</Illustrated>
							</li>
							<li>
								<Illustrated
									stones={[
										{ x: 3, y: 2, color: color.white },
										{ x: 2, y: 2, color: color.black }
									]}
									moves="m3x2t4x2d2x2"
									onClick={ this.showIllustration }
								>
									capture by withdrawal:&nbsp;
								</Illustrated>
							</li>
						</ul>
					</div>


					<div>
						<h5 className="big-title">The rules in <strong className="important">riatra</strong> game</h5>
						<h6><span className="rule">Rule-6</span> Moves must be done by turns.</h6>
						<h6><span className="rule">Rule-7</span> White has the first <strong className="important">turn</strong> in the game.</h6>
						<h6><span className="rule">Rule-8</span> Game is over when black/white cannot make move when it is his turn.</h6>
						<p>
							For example, when all black/white stones are removed from board.
						</p>
						<h6>
							<Illustrated
								stones={[
									{ x: 8, y: 2, color: color.white },
									{ x: 6, y: 1, color: color.white },
									{ x: 8, y: 4, color: color.black },
									{ x: 7, y: 3, color: color.black }
								]}
								moves="m8x2t8x3d8x4"
								onClick={ this.showIllustration }
							>
								<span className="rule">Rule-9</span>If there is stone to capture, the capturing move is mandatory.
							</Illustrated>
						</h6>
						<p>
							Otherwise, it is allowed to make only one non-capturing move.<br/>
							That non-capturing move is called&nbsp; 
							<Illustrated
								stones={[
									{ x: 8, y: 3, color: color.black },
									{ x: 6, y: 1, color: color.white },
									{ x: 8, y: 4, color: color.white },
									{ x: 7, y: 3, color: color.black }
								]}
								moves="m6x1t6x2"
								onClick={ this.showIllustration }
							>
								<strong className="important">paika</strong>
							</Illustrated>
						</p>
						<h6>
							<Illustrated
								stones={[
									{ x: 1, y: 2, color: color.white },
									{ x: 3, y: 2, color: color.black },
									{ x: 4, y: 2, color: color.black },
									{ x: 5, y: 2, color: color.black },
									{ x: 6, y: 2, color: color.white },
									{ x: 7, y: 2, color: color.black },
									{ x: 8, y: 2, color: color.white }
								]}
								moves="m1x2t2x2d3x2d4x2d5x2"
								onClick={ this.showIllustration }
							>
								<span className="rule">Rule-10</span> On <strong className="important">movement line</strong>, it is mandatory to capture all opponent's <strong className="important">contiguous</strong> stone<strong className="important">s</strong> behind the <strong className="important">first captured stone</strong>.
							</Illustrated>
						</h6>
						<h6>
							<Illustrated
								stones={[
									{ x: 2, y: 2, color: color.white },
									{ x: 4, y: 4, color: color.black },
									{ x: 5, y: 3, color: color.black },
									{ x: 5, y: 1, color: color.black },
								]}
								moves="m2x2t3x3d4x4m3x3t4x2d5x1m4x2t3x1d5x3"
								onClick={ this.showIllustration }
							>
							<span className="rule">Rule-11</span> After the first capturing move, any number of capturing moves can be done <strong className="important">with  the same single stone</strong>  as long as there are stones to capture.
							</Illustrated>
						</h6>
						<p>
							Those successive moves are <strong className="important">considered to be one single move</strong> in Fanorona game.<br/>
							<Illustrated
								stones={[
									{ x: 2, y: 2, color: color.white },
									{ x: 4, y: 4, color: color.black },
									{ x: 5, y: 3, color: color.black },
									{ x: 5, y: 1, color: color.black },
								]}
								moves="m2x2t3x3d4x4m3x3t4x2d5x1b4x2"
								onClick={ this.showIllustration }
							>
								When we choose to stop while there are still capturing moves to be made, we say we make a <strong>break</strong> <FontAwesomeIcon icon={ faHandPaper }/>.
							</Illustrated>
						</p>
						<h6><span className="rule">Rule-12</span> It is not allowed to make two successive capturing moves on the same line.</h6>
						<p>
							<Illustrated
								stones={[
									{ x: 4, y: 4, color: color.black },
									{ x: 4, y: 3, color: color.white },
									{ x: 4, y: 0, color: color.black },
								]}
								moves="m4x3t4x2d4x4m4x2t4x1d4x0"
								onClick={ this.showIllustration }
							>
								Counter-example:&nbsp;
							</Illustrated>
						</p>
						<h6><span className="rule">Rule-13</span> It is not allowed to occupy twice the same intersection during successive capturing moves.</h6>
						<p>
							<Illustrated
								stones={[
									{ x: 2, y: 2, color: color.white },
									{ x: 4, y: 4, color: color.black },
									{ x: 5, y: 3, color: color.black },
									{ x: 5, y: 1, color: color.black },
									{ x: 4, y: 0, color: color.black },
								]}
								moves="m2x2t3x3d4x4m3x3t4x2d5x1m4x2t3x1d5x3m3x1t2x2d4x0"
								onClick={ this.showIllustration }
							>
								Counter-example:&nbsp;
							</Illustrated>
						</p>
						<h6><span className="rule">Rule-14</span> When game is over, the <strong className="important">winner</strong> is the player who have made the last <strong className="important">capturing/non-capturing</strong> move.</h6>
						<h6><span className="rule">Rule-15</span> The winner must play another game of riatra with the loser only after <strong className="important">losing</strong> a game of <strong className="important">vela</strong> with him.</h6>
						<h6><span className="rule">Rule-16</span> If both players agree that the game is impossible to finish, it is declared a draw.</h6>
					</div>


					<div>
						<h5 className="big-title">The rules in <strong className="important">vela</strong> game</h5>

						<h6><span className="rule">Rule-17</span> The loser in the <strong className="important">riatra</strong> game must begin the game.</h6>
						<p>
							So the loser plays the white stones.<br/>
							It is said that he eats the <strong className="important">vela</strong>.
						</p>

						<h6><span className="rule">Rule-18</span> The winner in the <strong className="important">riatra</strong> game can make move but cannot capture stone.</h6>
						<p>
							So the winner plays the black stones.<br/>
							It is said that he makes the <strong className="important">vela</strong> eat.
						</p>

						<h6>
							<Illustrated
								stones={ false }
								moves="m4x1t4x2d4x3m4x4t4x3m4x2t4x1d4x3m3x3t4x4m3x2t3x3d3x4m2x2t3x2m1x2t2x2d3x2"
								onClick={ this.showIllustration }
							>
								<span className="rule">Rule-19</span> White can capture only one stone during his turn.
							</Illustrated>
						</h6>


						<h6>
							<Illustrated
								stones={[
									{ x: 4, y: 4, color: color.white },{ x: 2, y: 3, color: color.white },
									{ x: 3, y: 2, color: color.white },{ x: 5, y: 1, color: color.white },
									{ x: 1, y: 2, color: color.white },{ x: 2, y: 2, color: color.white },
									{ x: 8, y: 3, color: color.white },{ x: 8, y: 4, color: color.white },
									{ x: 3, y: 4, color: color.white },{ x: 0, y: 2, color: color.white },
									{ x: 3, y: 3, color: color.white },{ x: 2, y: 1, color: color.white },
									{ x: 6, y: 0, color: color.white },{ x: 3, y: 0, color: color.white },
									{ x: 7, y: 3, color: color.white },{ x: 1, y: 0, color: color.white },
									{ x: 8, y: 1, color: color.white },{ x: 7, y: 2, color: color.white },
									{ x: 5, y: 0, color: color.white },{ x: 6, y: 3, color: color.white },
									{ x: 2, y: 4, color: color.white },{ x: 6, y: 1, color: color.white },
									{ x: 4, y: 0, color: color.black },{ x: 5, y: 3, color: color.black },
									{ x: 1, y: 1, color: color.black },{ x: 0, y: 3, color: color.black },
									{ x: 6, y: 4, color: color.black },{ x: 0, y: 4, color: color.black },
								]}
								moves="m2x2t1x3d0x4m1x1t2x2d3x3d4x4m2x2t3x1d1x3m5x1t6x2d4x0m6x2t7x1d5x3"
								onClick={ this.showIllustration }
							>
								<span className="rule">Rule-20</span> When only five black stones remain, the rules of riatra game apply.
							</Illustrated>
						</h6>

						<h6>
							<Illustrated
								stones={ false }
								moves="m4x1t4x2d4x3m4x4t4x3m4x2t4x1d4x3m5x3t4x2"
								onClick={ this.showIllustration }
							>
								<span className="rule">Rule-21</span> if the rules of riatra game do not apply yet and <strong className="important">no capturing move is possible for the whites</strong>, the game is over and <strong className="important">the whites is the winner</strong>
							</Illustrated>
						</h6>
						<p>In this case, the whites can not make <strong className="important">paika</strong></p>

					</div>
		

				</div>
			</div>
  	)
	}
}

export default Learn
