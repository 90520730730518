
function getLineParam(line){
	const dy = line.y1 - line.y2
	const dx = line.x1 - line.x2
	if(dx === 0){
		return {
			a: 1,
			b: 0,
			c: -line.x1
		}
	}
	if(dy === 0){
		return {
			a: 0,
			b: 1,
			c: -line.y1
		}
	}
	const a = -dy/dx
	return {
		a,
		b: 1,
		c: -line.y1 - ( a * line.x1 )
	}
}

export function equalityOfTwoLines(line1, line2){
	const p1 = getLineParam(line1)
	const p2 = getLineParam(line2)
	return p1.a === p2.a && p1.b === p2.b && p1.c === p2.c
}
