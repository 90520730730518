import React, { Component } from 'react'
import './SelectedStone.css'
import Stone from '../stone/Stone.js'
import { motion } from 'framer-motion'

const transition = {
	ease: "easeOut",
	duration: 2,
	loop: Infinity
}

class SelectedStone extends Component {

	static propTypes = {
		children: function(props, propName, componentName){
			if(!(props[propName].type === Stone)){
				return new Error(
					'child of '+componentName+' must be only one element of type Stone.'
				)
			}
		}
	}

	d(){
		return this.props.children.props.f / 10 
	}

	render(){
		const x = []
		const y = []

		for(let i = -1; i <= 1; i++){
			for(let j = -1; j <= 1; j++){
				if(i !== 0 || j !== 0){
					x.push(0)
					y.push(0)
					x.push(i * this.d())
					y.push(j * this.d())
				}
			}
		}

		x.push(0)
		y.push(0)

		return (
			<motion.g
				animate = {{ x, y }}
				transition = { transition }
			>
			{ this.props.children }
			</motion.g>
		)
	}
}

export default SelectedStone
