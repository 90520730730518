import React, { Component } from 'react'
import './Stone.css'
import PropTypes from 'prop-types'
import color from '../fanorona/color.js'

class Stone extends Component {

	constructor(props){
		super(props)
		this.handleClick = this.handleClick.bind(this)
	}

	handleClick(e){
		if(this.props.handleClick){
			this.props.handleClick({
				x: this.props.x,
				y: this.props.y,
				color: this.props.color
			})
		}
		e.stopPropagation()
	}

	static propTypes = {
		color: PropTypes.oneOf(Object.keys(color))
	}

	render(){
		return (
			<circle 
				className={ this.props.color === color.white ? color.white : color.black }
				cx={ this.props.x * this.props.f } 
				cy={ this.props.y  * this.props.f} 
				r={ this.props.r }
				onClick={ this.handleClick }
			/>
		)
	}
}

export default Stone
