import color from './color.js'
import typeOfMove from './typeOfMove.js'

const regexpBString = 'b\\d+x\\d+'
const regexpMString = 'm\\d+x\\d+t\\d+x\\d+'
const regexpDString = 'd\\d+x\\d+'
const regexpNString = 'n(?:w|b)\\d+x\\d+'

export const regexpTestMove = new RegExp('^(?:\\B|'+regexpMString+'|'+regexpDString+'|'+regexpNString+'|'+regexpBString+')+$')

export const regexpExtractMoves = new RegExp(regexpMString+'|'+regexpDString+'|'+regexpNString+'|'+regexpBString, 'g')

export function positionToString({x,y}){
	return x.toString() + "x" + y.toString()
}

export const regexpExtractDetails = /^[mdb]|^n[wb]|\d+x\d+/g

export function stoneAndHisDestinationToString({ stone, destination }){
	return "m" + positionToString(stone) + "t" + positionToString(destination)
}

export function breakToString(stone){
	return "b" + positionToString(stone)
}

export function deletionToString(stone){
	return "d" + positionToString(stone)
}

export function creationToString(stone){
	let c= ""
	if(stone.color === color.black){
		c= "b"
	}
	if(stone.color === color.white){
		c= "w"
	}
	return "n"+ c + positionToString(stone)
}

export function explodeMoves(moves){
	const r = moves.match(regexpExtractMoves)
	return r ? r : []
}

export function extractDetailsFromMove(move){
	const ar = move.match(regexpExtractDetails)
	const t = ar[0]
	const s = []
	for(let i = 1; i < ar.length; i++){
		const stn = ar[i].match(/\d+/g)
		const x = parseInt(stn[0], 10)
		const y = parseInt(stn[1], 10)
		s.push({ x, y })
	}
	return {
		type: t,
		positions: s
	}
}

export function getArrivalIntersection(move){
	const details = extractDetailsFromMove(move)
	if(details.type === typeOfMove.m){
		return details.positions[1]
	}
	if(details.type === typeOfMove.nw || details.type === typeOfMove.nb){
		return details.positions[0]
	}
	return null
}

export function getLine(move){
	const details = extractDetailsFromMove(move)
	if(details.type === typeOfMove.m){
		return { 
			x1: details.positions[0].x,
			y1: details.positions[0].y,
			x2: details.positions[1].x,
			y2: details.positions[1].y
		}
	}
	return null
}

export function listOfMovesToString(listOfMoves){
	let result = "" 
	listOfMoves.forEach((move)=>{
		result = result.concat(move)
	})
	return result
}
