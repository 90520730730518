import color from './color.js'
import way from './way.js'
import { 
	regexpTestMove, 
	regexpExtractMoves,
	extractDetailsFromMove
} from './notation.js'
import { 
	OutOfGameBoard, 
	MoveIsNotRecognized, 
	StoneAlreadyExists,
	StoneDoesNotExist
} from './exception.js'
import typeOfMove from './typeOfMove.js'
import animationType from './animationType.js'
import { equalityOfTwoLines } from './lineUtilities.js'

class Board{

	constructor(col, row, wayToFill){
		this.col = col
		this.row = row
		this.stones = this.fill(wayToFill)
		this.lines = this.draw()
	}

	maxRowNumber(){
		return this.row - 1
	}

	maxColNumber(){
		return this.col - 1
	}

	middleLineY(){
		return this.maxRowNumber() / 2
	}

	middleLineX(){
		return this.maxColNumber() / 2
	}

	fill(wayToFill){
		let stones = []

		for(let x = 0; x < this.col; x++){
			for(let y = 0; y < this.middleLineY(); y++){
				let stone = {
					x, y, color: color.white
				}
				stones.push(stone)
			}
		}

		for(let x = 0; x < this.col; x++){
			for(let y = this.middleLineY() + 1; y < this.row; y++){
				let stone = {
					x, y, color: color.black
				}
				stones.push(stone)
			}
		}


		let y = this.middleLineY()
		let c = color.black
		if(wayToFill === way.left){
			c = color.white
		}
		for(let x = 0; x < this.middleLineX(); x++){
			let stone = {
				x, y, color: c
			}
			stones.push(stone)
			c = ( c === color.black ? color.white : color.black )
		}
		for(let x = this.middleLineX() + 1; x < this.col; x++){
			let stone = {
				x, y, color: c
			}
			stones.push(stone)
			c = ( c === color.black ? color.white : color.black )
		}

		return stones
	}

	draw(){
	 let lines = []
	 for(let x = 0; x < this.col; x++){
			lines.push({ 
				x1 : x, 
				y1 : 0, 
				x2 : x, 
				y2 : this.maxRowNumber() 
			})
	 }

	 for(let y = 0; y < this.row; y++){
			lines.push({ 
				x1 : 0, 
				y1 : y, 
				x2 : this.maxColNumber(), 
				y2 : y 
			})
	 }

	 let dy = 1
	 for(let y = 0; y < this.maxRowNumber(); y++){
		 let x1 = 0
		 let y1 = y
		 if(dy === -1){
			 y1 = y1 + 1
		 }
		 while(x1 < this.maxColNumber()){
			 let x2 = x1 + 1
			 let y2 = y1 + dy
			 lines.push({ x1, y1, x2, y2 })
			 x1 = x2
			 y1 = y2
			 dy *= -1
		 }
		 dy *= -1
	 }
	 
	 return lines
	}

	static dissect(move){
		return move.match(regexpExtractMoves)
	}

	execMoves(moves){
		if(!regexpTestMove.test(moves)){
			throw new MoveIsNotRecognized(moves)
		}
		let m = moves.match(regexpExtractMoves)
		if(m){
			m.forEach((move) => {
				this.execMove(move)
			})
		}
	}

	testIfPositionIsValid({ x, y }){
		if(x > this.maxColNumber()){
			throw new OutOfGameBoard({ x, y })
		}
		if(y > this.maxRowNumber()){
			throw new OutOfGameBoard({ x, y })
		}
	}

	getStone(position){
		return this.stones[this.indexOfStone(position)]
	}

	moveToAnimation(move){
		const details = extractDetailsFromMove(move)

		let stone, type, to
		switch(details.type){
			case typeOfMove.b:
				type = animationType.break
				stone = this.getStone(details.positions[0])
				break
			case typeOfMove.m:
				type = animationType.move
				stone = this.getStone(details.positions[0])
				to = details.positions[1]
				break
			case typeOfMove.d:
				type = animationType.deletion
				stone = this.getStone(details.positions[0])
				break
			case typeOfMove.nb:
				type = animationType.creation
				stone = { ...details.positions[0], color: color.black }
				break
			case typeOfMove.nw:
				type = animationType.creation
				stone = { ...details.positions[0], color: color.white }
				break
			default:
		}

		return { stone, type, to }
	}

	
	execMove(move){
		const details = extractDetailsFromMove(move)
		switch(details.type){
			case typeOfMove.b:
				this.b(details.positions[0])
				break
			case typeOfMove.m:
				this.m({ a: details.positions[0], b: details.positions[1] })
				break
			case typeOfMove.d:
				this.d(details.positions[0])
				break
			case typeOfMove.nb:
				this.n({...details.positions[0], color: color.black })
				break
			case typeOfMove.nw:
				this.n({...details.positions[0], color: color.white })
				break
			default:
		}
	}

	m(positions){
		const indexA = this.indexOfStone(positions.a)
		if(indexA > -1){
			const indexB = this.indexOfStone(positions.b)
			if(indexB === -1){
				this.stones[indexA].x = positions.b.x
				this.stones[indexA].y = positions.b.y
			} else {
				throw new StoneAlreadyExists(positions.b)
			}
		} else {
			throw new StoneDoesNotExist(positions.a)
		}
	}

	d(stone){
		const index = this.indexOfStone(stone)
		if(index > -1){
			this.stones.splice(index, 1)
		} else {
			throw new StoneDoesNotExist(stone)
		}
	}

	n(stone){
		if(this.stoneExists(stone)){
			throw new StoneAlreadyExists(stone)
		} else {
			this.stones.push(stone)
		}
	}

	b(stone){
		if(this.stoneExists(stone)){
			//ok
		} else {
			throw new StoneDoesNotExist(stone)
		}
	}

	indexOfStone({x, y}){
		this.testIfPositionIsValid({ x, y })
		return this.stones.findIndex((stone) => {
			return stone.x === x && stone.y === y
		})
	}

	stoneExists(position){
		return ( this.indexOfStone(position) > -1 )
	}

	lineExists(l){
		return !(this.lines.find((line) => equalityOfTwoLines(line, l)) === undefined)
	}
}

export default Board
