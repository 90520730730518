import React from 'react'
import './Logo.css'

function Logo(props){
	return (
		<svg 
			className="logo"
			height="100%"
			width="100%"
			viewBox="-10,-10,420,320"
		>
			<path className="logo-border" d="M 100 0 L 200 100 L 300 0 L 400 100 L 200 300 L 0 100 Z"/>
			<path d="M 0 100 L 400 100"/>
			<path d="M 100 200 L 300 200"/>
			<path d="M 100 0 L 100 200"/>
			<path d="M 200 100 L 200 300"/>
			<path d="M 300 0 L 300 200"/>
			<path d="M 200 100 L 100 200"/>
			<path d="M 200 100 L 300 200"/>
		</svg>
	)
}

export default Logo
