import React, { Component } from 'react'
import ClipboardJS from 'clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-solid-svg-icons' 
import $ from "jquery"

class CopyToClipboardButton extends Component {

	constructor(props){
		super(props)
		this.btnRef = React.createRef()
		this.handleSuccess = this.handleSuccess.bind(this)
		this.handleError = this.handleError.bind(this)
		this.handleClick = this.handleClick.bind(this)
		this.handleBlur = this.handleBlur.bind(this)
		this.handleFocus = this.handleFocus.bind(this)
		this.getTooltipTitle = this.getTooltipTitle.bind(this)
		this.handleTooltipHidden = this.handleTooltipHidden.bind(this)
		this.handleTooltipShown = this.handleTooltipShown.bind(this)
		this.state = {
			focus: false,
			tooltipTitle: "copy to clipboard",
			tooltipShown: false
		}
	}

	handleTooltipHidden(){
		if(this.state.shown === true){
			this.setState({ tooltipShown: false })
		}
	}

	handleTooltipShown(){
		if(this.state.shown === false){
			this.setState({ tooltipShown: true })
		}
	}

	handleFocus(){
		this.setState({ focus: true })
	}

	handleBlur(){
		this.setState({ focus: false, tooltipTitle: "copy to clipboard", tooltipShown: false })
	}

	handleClick(){
		this.setState({ focus: true })
	}

	handleSuccess(){
		this.setState({ focus: true, tooltipTitle: "copied successfully", tooltipShown: true })
	}

	handleError(){
		this.setState({ focus: true, tooltipTitle: "an error has occured", tooltipShown: true })
	}

	componentDidUpdate(prevProps, prevState){
		if(this.state.focus !== prevState.focus){
			if(this.state.focus === false){
				this.btnRef.current.blur()
			}
			if(this.state.focus === true){
				this.btnRef.current.focus()
			}
		}
		if(this.state.tooltipShown !== prevState.tooltipShown){
			const action = this.state.tooltipShown ? "show" : "hide"
			$(this.btnRef.current).tooltip(action)
		}
	}

	getTooltipTitle(){
		return this.state.tooltipTitle
	}

	componentDidMount(){
		const clipboard = new ClipboardJS(this.btnRef.current)
		clipboard.on('success', this.handleSuccess)
		clipboard.on('error', this.handleError)
		
		$(this.btnRef.current).tooltip({
			placement: "top",
			trigger: "manual",
			title: this.getTooltipTitle
		})

		$(this.btnRef.current).on("hidden.bs.tooltip", this.handleTooltipHidden)
		$(this.btnRef.current).on("shown.bs.tooltip", this.handleTooltipShown)
	}

	componentWillUnmount(){
		const clipboard = new ClipboardJS(this.btnRef.current)
		clipboard.destroy()
	}
	
  render(){
		 return ( 
			 <React.Fragment>
			 		<button
			 			ref={ this.btnRef }
						className="btn btn-outline-dark" 
			 			data-clipboard-text={ this.props.textToCopy }
						disabled={ this.props.disabled }
			 			onClick={ this.handleClick }
			 			onFocus={ this.handleFocus }
			 			onBlur={ this.handleBlur }
			 		>
			 			<FontAwesomeIcon icon={ faClipboard }/>&nbsp;copy
			 		</button>
			 </React.Fragment>
		 )
  }
}

export default CopyToClipboardButton
