import React, { Component } from 'react'
import './Author.css'
import photo from './photo.png'

class Author extends Component {
  render(){
		return (
			<div className="row">
				<div className="col-lg-6 offset-lg-3">
					<p>
							<img className="photo" src={ photo } alt="profile"/>
							<strong>Rijaniaina Alain RAMANDIMBISOA</strong><br/>
							<strong>Full-Stack Web Developer</strong><br/>
							<strong>15 years of experience</strong> in software development<br/>
					</p>
					<p>
							Transforming an idea into codes is for me a real pleasure.<br/>
							<strong>Languages I speak:</strong><br/>
								Assembler,&nbsp; 
								Visual Basic,&nbsp; 
								C,&nbsp; 
								C++,&nbsp; 
								Java, 
								Php,&nbsp; 
								Javascript,&nbsp; 
								CSS3,&nbsp; 
								HTML5,&nbsp; 
								SQL,&nbsp; 
								GraphQL,
								Malagasy,&nbsp;
								French,&nbsp;
								English<br/>
							<strong>Frameworks and libraries I use:</strong><br/>
								Symfony,&nbsp; ReactJs,&nbsp; NodeJs,&nbsp; Qt<br/>
							<strong>Vim</strong> is my favorite IDE.<br/>
							<strong>Version Control System</strong> I use: Git
					</p>
					<a 
						className="btn my-btn-primary"
						href="https://www.upwork.com/o/profiles/users/_~016fe32e10ba717098/"
					>
						hire me
					</a>
				</div>
			</div>
  	)
	}
}

export default Author
