import React, { Component } from 'react'
import './ListOfMoves.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cursor from '../cursor/Cursor.js'
import { 
	faPlay, 
	faFastBackward, 
	faFastForward,
	faStepBackward,
	faStepForward,
	faStop,
	faTimes
} from '@fortawesome/free-solid-svg-icons' 
import Move from '../move/Move.js'
import { listOfMovesToString } from '../fanorona/notation.js'
import CopyToClipboardButton from '../copyToClipboardButton/CopyToClipboardButton.js'

class ListOfMoves extends Component {

	static defaultProps = {
		hideList: false,
		deleteBtnDisabled: false,
		readOnly: false
	}

	constructor(props){
		super(props)
		this.handleFirstMove = this.handleFirstMove.bind(this)
		this.handlePreviousMove = this.handlePreviousMove.bind(this)
		this.handleNextMove = this.handleNextMove.bind(this)
		this.handleLastMove = this.handleLastMove.bind(this)
		this.handleClickOnMove = this.handleClickOnMove.bind(this)
		this.handlePlay = this.handlePlay.bind(this)
		this.handleStop = this.handleStop.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
	}

	navigationDisabled(){
		return this.props.navigationDisabled || (this.props.listOfMoves.length === 0)
	}

	handleStop(){
		this.props.handleStop()
	}

	handleClickOnMove(index){
		this.props.handleClickOnMove(index)
	}

	maxCursor(){
		return this.props.listOfMoves.length - 1
	}

	minCursor(){
		return -1
	}

	cursorIsMin(){
		return this.props.cursor === this.minCursor()
	}

	cursorIsMax(){
		return this.props.cursor === this.maxCursor()
	}

	handlePlay(){
		this.props.handlePlay()
	}

	handleFirstMove(){
		this.props.handleFirstMove()
	}

	handlePreviousMove(){
		this.props.handlePreviousMove()
	}

	handleNextMove(){
		this.props.handleNextMove()
	}

	handleLastMove(){
		this.props.handleLastMove()
	}

	deleteBtnDisabled(){
		return ( this.props.deleteBtnDisabled || this.props.listOfMoves.length === 0 || this.cursorIsMax())
	}

	handleDelete(){
		this.props.handleDelete()
	}

	textToCopy(){
		return listOfMovesToString(this.props.listOfMoves)
	}
	
  render(){
		 return ( 
			 <div className="card">
				<div className="card-header d-flex flex-row p-1 align-items-center justify-content-center my-card-header">
			 		<CopyToClipboardButton
			 			textToCopy={ this.textToCopy() }
			 			disabled={ this.props.listOfMoves.length === 0 }
			 		/>
			 		{ this.props.readOnly === false && 
						<button 
							className="btn btn-default" 
							onClick={ this.handleDelete }
							disabled={ this.deleteBtnDisabled() }
						>
							<FontAwesomeIcon icon={ faTimes }/>
						</button>
					}
			 		<div className="btn-group">
			 			<button 
			 				className="btn btn-default" 
			 				onClick={ this.handleFirstMove }
			 				disabled={ this.cursorIsMin() || this.navigationDisabled() }
			 			>
			 				<FontAwesomeIcon icon={ faFastBackward } />
			 			</button>
			 			<button 
			 				className="btn btn-default" 
			 				onClick={ this.handlePreviousMove }
			 				disabled={ this.cursorIsMin() || this.navigationDisabled() }
			 			>
			 				<FontAwesomeIcon icon={ faStepBackward } />
			 			</button>
			 			{ this.props.playing === false &&
							<button 
								className="btn btn-default"
								onClick={ this.handlePlay }
								disabled={ this.cursorIsMax() || this.navigationDisabled() }
							>
								<FontAwesomeIcon icon={ faPlay } />
							</button>
						}
			 			{ this.props.playing === true &&
							<button 
								className="btn btn-default"
								onClick={ this.handleStop }
							>
								<FontAwesomeIcon icon={ faStop } />
							</button>
						}
			 			<button 
			 				className="btn btn-default" 
			 				onClick={ this.handleNextMove }
			 				disabled={ this.cursorIsMax() || this.navigationDisabled() }
			 			>
			 				<FontAwesomeIcon icon={ faStepForward } />
			 			</button>
			 			<button 
			 				className="btn btn-default" 
			 				onClick={ this.handleLastMove }
			 				disabled={ this.cursorIsMax() || this.navigationDisabled() }
			 			>
			 				<FontAwesomeIcon icon={ faFastForward } />
			 			</button>
			 		</div>
				</div>			 	 
			 	 { this.props.hideList === false && 
				 <div className="card-body p-1">
						<div className="d-flex flex-row flex-wrap">
			 				{ this.props.listOfMoves.length === 0 && 
								(<span className="text-muted">Empty</span>) 
							}
			 				{ (this.props.cursor === -1 && this.props.listOfMoves.length > 0) && (
								<div>
									<Cursor />
								</div>
							)}
			 				{ this.props.listOfMoves.map((move, index) => (
								<div key={ index } className="d-flex">
									<Move 
										move={ move } 
										onClick={ this.handleClickOnMove } 
										index={ index }
										disabled={ this.navigationDisabled() }
									/>
									{ index === this.props.cursor && (
										<Cursor />
									)}
								</div>
							))}
						</div>
				 </div>
				 }
			 </div>
		 )
  }

}

export default ListOfMoves
