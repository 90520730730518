import React, { Component } from 'react'
import './Home.css'
import { motion } from 'framer-motion'
import { Link } from "react-router-dom"
import route from "../route.js"
import Logo from "../logo/Logo.js"

class Home extends Component {

  render(){
		return (
			<div className="row">
				<div className="col-lg-6 offset-lg-3">
					<div className="home">
						<motion.h1 
							className="fanorona"
							initial={{ scale: 2, opacity: 0 }}
							animate={{
								scale: 1,
								opacity: 1
							}}
							transition={{
								type: "spring",
								stiffness: 260,
								damping: 20,
								delay: 0.5
							}}
						>
							Fanorona
						</motion.h1>
						<motion.h3 
							className="subtitle"
							initial={{ opacity: 0, x: -100 }}
							animate={{
								x: 0,
								opacity: 1
							}}
							transition={{
								type: "spring",
								stiffness: 260,
								damping: 20,
								delay: 1
							}}
						>
							<Link to={ route.learn }>
								Learn fanorona game interactively
							</Link>
						</motion.h3>
						<motion.h3 
							className="subtitle"
							initial={{ opacity: 0, x: 100 }}
							animate={{
								x: 0,
								opacity: 1
							}}
							transition={{
								type: "spring",
								stiffness: 260,
								damping: 20,
								delay: 1.5
							}}
						>
							<Link to={ route.boardEditor }>
								Edit a fanorona board
							</Link>
						</motion.h3>
						<motion.div 
							className="logo-home"
							initial={{ scale: 2, opacity: 0 }}
							animate={{
								scale: 1,
								opacity: 1
							}}
							transition={{
								type: "spring",
								stiffness: 260,
								damping: 20,
								delay: 2
							}}
						>
							<Logo/>
						</motion.div>
					</div>
				</div>
			</div>
  	)
	}

}

export default Home
